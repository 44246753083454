import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '100%',
    height: '100%',
    filter: 'saturate(600%) hue-rotate(316deg) brightness(650%)', // turn black png to splash-blue
  },
}));

interface BoostIconProps {
  iconUrl: string;
  size?: number;
}

function BoostIcon({ iconUrl, size = 24 }: BoostIconProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.iconContainer} style={{ width: size, height: size }}>
      <img className={classes.icon} src={`${iconUrl}`} alt="boost icon" />
    </div>
  );
}

export { BoostIcon };
