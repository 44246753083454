import type { APIGroupSlip } from '../interfaces/propsService/api';
import type { TGroupSlip } from '../interfaces/propsService/parsed/slips';

import parseUserProp from './parseUserProp';

export const parseGroupSlip = (response: APIGroupSlip): TGroupSlip => ({
  ...response,
  created_at: new Date(response.created_at),
  props: response.props.map(parseUserProp),
});
