import type { APISlip } from '../interfaces/propsService/api';
import type { TSlip } from '../interfaces/propsService/parsed/slips';

import parseUserProp from './parseUserProp';

export const parseSlip = (response: APISlip): TSlip => ({
  ...response,
  created_at: new Date(response.created_at),
  props: response.props.map(parseUserProp),
  metadata: {
    parent_remaining_amount: response.remaining_amount,
    parent_refunded_amount: response.refunded_amount,
    children_count: response.children_count,
  },
});
