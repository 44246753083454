import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';

const getSafeLeagueAlias = (leagueAlias: string): string =>
  [
    //
    'nfl',
    'nba',
    'pga',
    'nhl',
    'ncaab',
  ].find((alias) => leagueAlias.startsWith(alias)) || leagueAlias;

export const getTeamOrPlayerImageUrl = (leagueAlias: string, teamOrPlayerId: string): string =>
  getCdnUrl(`/${getSafeLeagueAlias(leagueAlias)}/${teamOrPlayerId}.png`);
