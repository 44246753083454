import { useQuery } from '@tanstack/react-query';

import { STALE_TIME } from '~/domains/contest/constants/query';

import { getSettings } from '../../services/props.service.api';
import QuickPickQueryKeys from '../constants/query';

export const useQuickPicksSettings = () =>
  useQuery({
    queryKey: QuickPickQueryKeys.QUICK_PICKS_SETTINGS(),
    queryFn: () => getSettings(),
    staleTime: STALE_TIME,
    refetchInterval: 30 * 60 * 1000,
  });
