import type { APIGetPropsResponse } from '../interfaces/propsService/api';
import type { TGetPropsResponse } from '../interfaces/propsService/parsed/props';

import parsePropExpanded from './parsePropExpanded';

const parsePropsResponse = (response: APIGetPropsResponse): TGetPropsResponse => ({
  ...response,
  data: response.data.map(parsePropExpanded),
});

export default parsePropsResponse;
