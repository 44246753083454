/** @deprecated - Use ~/domains/design-system/components/Dialog instead */
import { Modal as BaseModal, Drawer, useMantineTheme } from '@mantine/core';
import { useScrollLock } from '@mantine/hooks';
import useTranslation from 'next-translate/useTranslation';
import type { ComponentProps } from 'react';
import { useEffect } from 'react';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

function Modal({
  children,
  isOpened = true,
  onClose,
  classNames,
  lockScroll,
  dataTestId,
  withCloseButton,
  closeOnClickOutside,
  size,
}: {
  children: React.ReactNode;
  isOpened?: boolean;
  onClose: () => void;
  size?: ComponentProps<typeof BaseModal>['size'];
  classNames?: {
    modal?: ComponentProps<typeof BaseModal>['classNames'];
    drawer?: ComponentProps<typeof Drawer>['classNames'];
  };
  lockScroll?: boolean;
  dataTestId?: string;
  withCloseButton?: boolean;
  closeOnClickOutside?: boolean;
}) {
  const theme = useMantineTheme();
  const isMobile = useIsMobile();
  const [, setScrollLocked] = useScrollLock();
  const { t } = useTranslation('common');

  useEffect(() => {
    setScrollLocked(lockScroll);

    return () => {
      setScrollLocked(false);
    };
  }, [lockScroll, setScrollLocked]);

  if (isMobile) {
    return (
      <Drawer
        onClose={onClose}
        closeButtonLabel={t('modal.close')}
        opened={isOpened}
        position="bottom"
        withCloseButton={withCloseButton}
        closeOnClickOutside={closeOnClickOutside}
        classNames={classNames?.drawer}
        styles={{
          header: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            margin: 0,
            zIndex: 2,
          },
          closeButton: {
            position: 'absolute',
            top: theme.other.spacing._16,
            right: theme.other.spacing._16,
          },
          root: {
            maxHeight: '100%',
            overflow: 'auto',
          },
          drawer: {
            height: 'auto',
            position: 'absolute',
            background: theme.white,
            borderTopLeftRadius: theme.other.spacing._32,
            borderTopRightRadius: theme.other.spacing._32,
          },
          body: {
            padding: theme.other.spacing._16,
            paddingTop: 0,
          },
        }}
        data-test-id={dataTestId}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <BaseModal
      transition="pop"
      styles={{
        header: {
          position: 'absolute',
          top: theme.other.spacing._16,
          right: theme.other.spacing._16,
          margin: 0,
        },
        root: {
          backdropFilter: 'blur(2px)',
        },
        close: {
          zIndex: 10,
        },
        modal: {
          borderRadius: theme.other.spacing._8,
        },
      }}
      centered
      onClose={onClose}
      closeButtonLabel={t('modal.close')}
      opened={isOpened}
      classNames={classNames?.modal}
      data-test-id={dataTestId}
      withCloseButton={withCloseButton}
      closeOnClickOutside={closeOnClickOutside}
      trapFocus={false}
      size={size}
    >
      {children}
    </BaseModal>
  );
}

export default Modal;
