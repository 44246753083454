import type { APIValidateSlipErrorResponse } from '../interfaces/propsService/api';
import type { TValidateSlipErrorResponse } from '../interfaces/propsService/parsed/slips';

import parseUserProp from './parseUserProp';

function parseValidateErrorResponse(
  response: APIValidateSlipErrorResponse
): TValidateSlipErrorResponse {
  return {
    ...response,
    data: {
      ...response.data,
      changes: Object.fromEntries(
        Object.entries(response.data.changes).map(([key, value]) => [key, parseUserProp(value)])
      ),
    },
  };
}

export default parseValidateErrorResponse;
