import type { APIGetGroupSlipResponse } from '../interfaces/propsService/api';
import type { TGetGroupSlipResponse } from '../interfaces/propsService/parsed/slips';

import { parseGroupSlip } from './parseGroupSlip';

export const parseGroupSlipResponse = (
  response: APIGetGroupSlipResponse
): TGetGroupSlipResponse => ({
  ...response,
  slip: parseGroupSlip(response.slip),
});
