import { createStyles } from '@mantine/core';
import React, { forwardRef } from 'react';

export type PillProps = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  selected?: boolean;
};

const useStyles = createStyles((theme) => ({
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    border: 0,
    borderRadius: '68px',
    padding: '8px 12px',
    cursor: 'pointer',
    fontFamily: theme.fontFamily,
    color: theme.colors.gray2[7],
    background: theme.white,
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '18px',
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',

      '&:not(:disabled):hover': {
        opacity: 0.5,
      },
    },
  },
  selected: {
    background: theme.colors.gray2[7],
    color: theme.white,
  },
}));

const PillFactory = <E extends React.ElementType>(component: E) =>
  // eslint-disable-next-line react/display-name
  forwardRef<E, PillProps & React.ComponentPropsWithoutRef<E>>(
    ({ children, selected, ...props }, ref) => {
      const { classes, cx } = useStyles();

      return React.createElement(
        component,
        {
          ref,
          ...props,
          className: cx(classes.base, props.className, { [classes.selected]: selected }),
        },
        children
      );
    }
  );

export const Pill = PillFactory('button');
Pill.displayName = 'Pill';
