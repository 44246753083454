import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';
import { handleRefreshInterceptor } from '~/services/interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '~/services/interceptors/set-auth-token.interceptor';
import { Method, setLocationInterceptor } from '~/services/interceptors/set-location.interceptor';

export const propsServiceInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_SPLASH}props-service/api`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(propsServiceInstance);
handleRefreshInterceptor(propsServiceInstance);

setLocationInterceptor(propsServiceInstance, [
  [Method.POST, '/slips'],
  [Method.GET, '/location_allowed'],
]);

// TODO Make one of these unauthenticated
